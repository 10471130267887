export function setCookie(name, value, days) {
  const date = new Date();
  // Set the expiration date
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  // Set cookie with name, value, expiration and path
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export function getCookie(name) {
  const cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    const cookie = cookieArr[i].trim();
    // Check if this cookie starts with the name
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
